.spinner-wrapper { 
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
  inset: 0;
}

.spinner { 
  border: 16px solid #f3f3f3;
  border-top: 16px solid #F46A35;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
