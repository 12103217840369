* {
  box-sizing: border-box;
}

.App {

}

.page {
  text-align: center;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  min-height: 100%;
  width: 100%;
  transition: 0.3s;
}

.page.modalOpen {
  filter: blur(4px);
}

.nav {
  background: white;
  /*#F46A35;*/
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);
  height: 60px;
  z-index: 1;
}

.nav > img {
  width: 100px;
  height: auto;
  margin-bottom: -3px;
}

.nav a {
  text-decoration: none;
  font-size: 0.6em;
  color: black;
}

.row {
  padding: 1em 2em;
  width: 100%;
  max-width: 999px;
  margin: auto;
}

.image-wrapper {
  max-width: 100%;
}

.image-wrapper img {
  width: 100%;
}