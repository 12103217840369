* {
  box-sizing: border-box;
}

/*.modal-wrapper {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: fixed;
  inset: 0;
}
*/
.modal-wrapper {
  position: fixed;
  z-index: 999;
  inset: 0;
  background: #33333333;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  height: 100%;
  width: 100%;
}

.modal {
  border: 2px solid #ededed;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: min(95vh, 180vw, 640px);
  max-height: min(95vh, 180vw, 860px);
  max-width: min(150vh, 95vw, 500px);
}

@media (max-width: 499px) {
  .modal-wrapper {
    align-items: flex-end;
  }

  .modal {
    border-radius: 24px;
  }
}
